.app {
  /* display: flex; */
  padding: 30px;
}

@media (max-height: 400px) {
  .game {
    width: 230px;
    height: 230px;
    display: flex;
    flex-wrap: wrap;
  }
  
  .game img {
    width: 28px;
    height: 28px;
  }
}

@media (min-height: 401px) and (max-height: 500px) {
  .game {
    width: 260px;
    height: 260px;
    display: flex;
    flex-wrap: wrap;
  }
  
  .game img {
    width: 30px;
    height: 30px;
  }
}

@media (min-height: 501px) and (max-height: 600px) {
  .game {
    width: 350px;
    height: 350px;
    display: flex;
    flex-wrap: wrap;
  }
  
  .game img {
    width: 40px;
    height: 40px;
  }
}

@media (min-height: 601px) and (max-height: 700px) {
  .game {
    width: 450px;
    height: 450px;
    display: flex;
    flex-wrap: wrap;
  }
  
  .game img {
    width: 55px;
    height: 55px;
  }
}

@media (min-height: 700px) {
  .game {
    width: 560px;
    height: 560px;
    display: flex;
    flex-wrap: wrap;
  }

  .game img {
    width: 70px;
    height: 70px;
  }
}


/* @media only screen and (max-height: 600px) {
  .game {
    width: 350px;
    height: 350px;
    display: flex;
    flex-wrap: wrap;
  }
  
  .game img {
    width: 40px;
    height: 40px;
  }
} */